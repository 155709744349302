import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import contact from "../images/contact.png";
import { Element } from "react-scroll";

const ContactForm = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const firstRender = useRef(true);

    const [disable, setDisabled] = useState(true);

    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [messageError, setMessageError] = useState(null);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setDisabled(formValidation());
    }, [firstName, lastName, email, message]);

    const formValidation = () => {
        if (firstName === "") {
            setFirstNameError("First Name cant be blank!");
            return true;
        } else if (lastName === "") {
            setLastNameError("Last Name cant be blank!");
            return true;
        } else if (email === "") {
            setEmailError(
                "Please enter a valid Email, e.g. john@moresecure.com"
            );
            return true;
        } else if (message === "") {
            setMessageError("Message cant be blank!");
            return true;
        } else {
            setFirstNameError(null);
            setLastNameError(null);
            setEmailError(null);
            setMessageError(null);
            return false;
        }
    };

    const alert = useRef(null);

    const onSubmit = () => {
        let formData = new FormData();
        formData.append("mauticform[first_name]", firstName);
        formData.append("mauticform[last_name]", lastName);
        formData.append("mauticform[email]", email);
        formData.append("mauticform[f_message]", message);
        formData.append("mauticform[formid]", 2);
        formData.append("mauticform[formName]", "mscontactus");
        formData.append("mauticform[messenger]", 1);

        fetch("https://mt.moresecure.com/form/submit?formId=2", {
            method: "post",
            body: formData,
            mode: "no-cors",
        })
            .then((data) => {
                console.log(data);
                if (alert.current) {
                    alert.current.innerText =
                        "Great, Thanks for contacting us, We will get back to you ASAP.";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Element id="contact" name="contact">
                <section className="contact">
                    <Container className="contact__container">
                        <Row className="contact__row">
                            <Col
                                xs={12}
                                sm={12}
                                md={11}
                                lg={9}
                                xl={8}
                                className="contact__col-left"
                            >
                                <div className="contact__form-wrapper">
                                    <div className="contact__form--heading">
                                        CONTACT US TODAY
                                    </div>
                                    <div className="contact__form--heading">
                                        TO MAKE SURE YOUR BUSINESS IS IN THE
                                        RIGHT DIRECTION
                                    </div>
                                    <span
                                        ref={alert}
                                        className="contact__alert"
                                    ></span>
                                    <div className="contact__form">
                                        <div className="contact__form-names">
                                            <label className="contact__form--label">
                                                First Name
                                                <input
                                                    onChange={(e) => {
                                                        setFirstName(
                                                            e.target.value
                                                        );
                                                    }}
                                                    name="mauticform[first_name]"
                                                    value={firstName}
                                                    placeholder="Your First Name"
                                                    className="contact__form--input"
                                                    type="text"
                                                ></input>
                                                {firstNameError && (
                                                    <span className="contact__error">
                                                        {firstNameError}
                                                    </span>
                                                )}
                                            </label>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <label className="contact__form--label">
                                                Last Name
                                                <input
                                                    onChange={(e) => {
                                                        setLastName(
                                                            e.target.value
                                                        );
                                                    }}
                                                    name="mauticform[last_name]"
                                                    value={lastName}
                                                    placeholder="Your Last Name"
                                                    className="contact__form--input"
                                                    type="text"
                                                ></input>
                                                {lastNameError && (
                                                    <span className="contact__error">
                                                        {lastNameError}
                                                    </span>
                                                )}
                                            </label>
                                        </div>

                                        <label className="contact__form--email-label">
                                            Email
                                            <input
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                name="mauticform[email]"
                                                value={email}
                                                placeholder="Your email address"
                                                className="contact__form--input-email"
                                                type="email"
                                            ></input>
                                            {emailError && (
                                                <span className="contact__error">
                                                    {emailError}
                                                </span>
                                            )}
                                        </label>

                                        <label className="contact__form--message-label">
                                            Message
                                            <textarea
                                                onChange={(e) => {
                                                    setMessage(e.target.value);
                                                }}
                                                rows="5"
                                                name="mauticform[f_message]"
                                                value={message}
                                                placeholder="Your Message"
                                                className="contact__form--input"
                                            ></textarea>
                                            {messageError && (
                                                <span className="contact__error">
                                                    {messageError}
                                                </span>
                                            )}
                                        </label>
                                        <div className="contact__form--button-wrapper">
                                            <button
                                                onClick={onSubmit}
                                                type="submit"
                                                name="mauticform[submit]"
                                                disabled={disable}
                                                className="contact__form--button"
                                            >
                                                SUBMIT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col className="contact__col-right">
                                <Image
                                    className="contact__image"
                                    src={contact}
                                    fluid={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Element>
        </>
    );
};

export default ContactForm;
