import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const OurValue = () => {
    return (
        <>
            <section className="ourvalue">
                <Container className="ourvalue__container">
                    <Row className="ourvalue__row">
                        <Col lg={2} xl={2}>
                            <div className="ourvalue__heading">Our Value</div>
                        </Col>
                        <Col lg={10} xl={10}>
                            <p className="ourvalue__description">
                                Empower More Secure customers to win more
                                business, to run business more efficiently and
                                securely with affordable open solutions.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <svg
                    className="ourvalue__upper-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        d="M0,224L48,224C96,224,192,224,288,240C384,256,480,288,576,282.7C672,277,768,235,864,229.3C960,224,1056,256,1152,272C1248,288,1344,288,1392,288L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                    ></path>
                </svg>
                <svg
                    className="ourvalue__lower-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        d="M0,160L48,160C96,160,192,160,288,170.7C384,181,480,203,576,192C672,181,768,139,864,138.7C960,139,1056,181,1152,192C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                    ></path>
                </svg>
            </section>
        </>
    );
};

export default OurValue;
